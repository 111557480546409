import { Box, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Header from "../componenets/Header";
import Footer from "../componenets/Footer";
import useCookie from "../hooks/useCookie";
import { useDevice } from "../hooks/useDevice";

export default function Root() {
  useCookie();

  const isDesktop = useDevice();

  return (
    <Box
      width="100%"
      backgroundColor="gray.100"
      display="flex"
      justifyContent="center"
      overflowX="hidden"
    >
      <VStack
        width="100%"
        maxWidth={isDesktop ? null : "480px"}
        spacing="12px"
        display="flex"
        justifyContent="center"
        backgroundColor="white"
      >
        <Header />
        <Box
          width="100%"
          minHeight="100svh"
          paddingBottom="128px"
          display="flex"
          justifyContent="center"
        >
          <Outlet />
        </Box>
        <Footer />
      </VStack>
      <ReactQueryDevtools />
    </Box>
  );
}
