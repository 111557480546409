import {
  Box,
  Button,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { createContact } from "../api";
import useTopScroll from "../hooks/useTopScroll";

export default function Contact() {
  useTopScroll();

  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const toast = useToast();
  const createContactMutation = useMutation(createContact, {
    onSuccess: () => {
      window.scrollTo(0, 0);
      toast({
        position: "bottom",
        duration: 1600,
        render: () => (
          <Box
            width="100%"
            marginBottom="16px"
            display="flex"
            justifyContent="center"
          >
            <Text
              paddingX="16px"
              paddingY="10px"
              borderRadius="8px"
              color="white"
              backgroundColor="blue.500"
              textAlign="center"
              fontFamily="PRT-SemiBold"
              fontSize="16px"
            >
              전송 완료
            </Text>
          </Box>
        ),
      });
      setEmail("");
      setText("");
    },
    onError: () => {
      toast({
        position: "bottom",
        duration: 1600,
        render: () => (
          <Box
            width="100%"
            marginBottom="16px"
            display="flex"
            justifyContent="center"
          >
            <Text
              paddingX="16px"
              paddingY="10px"
              borderRadius="8px"
              color="white"
              backgroundColor="red.400"
              textAlign="center"
              fontFamily="PRT-SemiBold"
              fontSize="16px"
            >
              전송 실패
            </Text>
          </Box>
        ),
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>문의하기</title>
        <meta property="og:title" content="문의하기" />
        <meta property="og:description" content="무엇을 도와드릴까요?" />
      </Helmet>
      <VStack width="90%" maxWidth="432px" spacing="12px">
        <Input
          className={createContactMutation.isLoading ? "" : "input"}
          type="text"
          fontFamily="PRT-Medium"
          placeholder={
            createContactMutation.isLoading ? "전송 중..." : "이메일 (선택사항)"
          }
          spellCheck={false}
          value={email}
          backgroundColor={
            createContactMutation.isLoading ? "gray.300" : "white"
          }
          isDisabled={createContactMutation.isLoading}
          onChange={(event) => {
            setEmail(event.target.value.trimStart());
          }}
        />
        <Textarea
          className={createContactMutation.isLoading ? "" : "input"}
          height="300px"
          resize="none"
          fontFamily="PRT-Medium"
          placeholder={
            createContactMutation.isLoading
              ? "전송 중..."
              : "무엇을 도와드릴까요?"
          }
          value={text}
          backgroundColor={
            createContactMutation.isLoading ? "gray.200" : "white"
          }
          isDisabled={createContactMutation.isLoading}
          onChange={(event) => {
            setText(event.target.value);
          }}
        />
        <Button
          width="100%"
          paddingY="24px"
          colorScheme="blue"
          display="flex"
          justifyContent="center"
          alignItems="center"
          isDisabled={!text}
          isLoading={createContactMutation.isLoading}
          onClick={() => {
            createContactMutation.mutate({
              email: email.trim(),
              text,
            });
          }}
        >
          <Text fontFamily="PRT-SemiBold" fontSize="20px">
            보내기
          </Text>
        </Button>
      </VStack>
    </>
  );
}
