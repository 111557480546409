import { Text, Button, Image, Spinner } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import useTopScroll from "../hooks/useTopScroll";
import { useDevice } from "../hooks/useDevice";

export default function Guide() {
  useTopScroll();
  const navigate = useNavigate();

  const isDesktop = useDevice();

  return (
    <>
      <Helmet>
        <title>타임픽 TimePick</title>
        <meta property="og:title" content="타임픽 TimePick" />
        <meta
          property="og:description"
          content="쉽고 빠르게 모임 시간을 정해보세요."
        />
      </Helmet>
      <Image
        width="100%"
        maxWidth="432px"
        src={`${process.env.PUBLIC_URL}/images/how_to_use.png`}
        alt={<Spinner />}
      />
      <Button
        width="100%"
        maxWidth={isDesktop ? null : "480px"}
        height="56px"
        position="fixed"
        bottom="0px"
        justifyContent="center"
        alignItems="center"
        colorScheme="blue"
        borderRadius="0px"
        onClick={() => {
          navigate("/");
        }}
      >
        <Text fontFamily="PRT-SemiBold" fontSize="24px">
          모임 만들러 가기
        </Text>
      </Button>
    </>
  );
}
