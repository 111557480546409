import { useEffect, useState } from "react";

export function useDevice() {
  const getIsDesktop = () => {
    return window.innerWidth > 1024;
  };

  const [isDesktop, setIsDesktop] = useState(getIsDesktop);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(getIsDesktop());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isDesktop;
}
